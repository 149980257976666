import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import Learn from '@clearscore/website.learn-portal';

import avatar from '../assets/global/avatar_global_learn_250x250.jpg';
import Layout from '../components/layout';

// todo: cms should filter out null articles,
const getArticlesFromNode = (
    articles: readonly Queries.LearnArticle[] = [],
): {
    id: string;
    url: string;
    title: string;
    description: string;
    author: string;
    category: string;
    categoryUrl: string;
    wordCount: number;
    timeToRead: number;
    publishedDate: string;
    formattedPublishDate: string;
    image: IGatsbyImageData;
}[] =>
    articles
        .filter(Boolean)
        .map(
            ({
                id,
                slug: url,
                language: { title, description },
                author: { name: author },
                props: { primaryCategory: category, primaryCategoryUrl: categoryUrl },
                publishedDate,
                formattedPublishDate,
                childMdx: { wordCount, timeToRead },
                heroImageNode,
            }) => ({
                id,
                url,
                title,
                description,
                author: `${author} ${author}`, // todo: this looks wrong, is it even used, what else?
                category,
                categoryUrl,
                wordCount: wordCount.words,
                timeToRead,
                publishedDate,
                formattedPublishDate,
                image: getImage(heroImageNode as unknown as ImageDataLike),
            }),
        );

const LearnPortal = ({ data: { learnPortal }, location }: PageProps<Queries.Query>): React.ReactElement => {
    const emptyArticleNode = {
        articlesNode: [],
        title: '',
        description: '',
        slug: '',
        language: { sectionTitle: '' },
        authors: [],
    };
    const {
        sections = [],
        meta,
        mostRecent = emptyArticleNode,
        categories = [],
        authorSection = emptyArticleNode,
    } = learnPortal;

    const formattedSections = sections.reduce<{
        [key: string]: Queries.LearnPortalSSGSection;
    }>(
        (acc, section, index) => ({
            ...acc,
            [`section${index + 1}`]: section,
        }),
        {},
    );

    const {
        section1 = emptyArticleNode,
        section2 = emptyArticleNode,
        section3 = emptyArticleNode,
        section4 = emptyArticleNode,
    } = formattedSections;

    const section1Articles = getArticlesFromNode(section1.articlesNode as Queries.LearnArticle[]);
    const section2Articles = getArticlesFromNode(section2.articlesNode as Queries.LearnArticle[]);
    const section3Articles = getArticlesFromNode(section3.articlesNode as Queries.LearnArticle[]);
    const section4Articles = getArticlesFromNode(section4.articlesNode as Queries.LearnArticle[]);
    const mostRecentArticles = getArticlesFromNode(mostRecent.articlesNode as Queries.LearnArticle[]);

    return (
        <Layout meta={{ ...meta, image: avatar }} location={location.pathname}>
            {({ commonModules }): React.ReactElement => (
                <Learn
                    section1={{
                        title: section1.title,
                        description: section1.description,
                        // @ts-expect-error fix after migration to TS
                        items: section1Articles.slice(0, 2),
                    }}
                    section2={{
                        title: section2.title,
                        description: section2.description,
                        categoryUrl: section2.slug,
                        // @ts-expect-error fix after migration to TS
                        items: section2Articles,
                    }}
                    section3={{
                        title: section3.title,
                        description: section3.description,
                        categoryUrl: section3.slug,
                        // @ts-expect-error fix after migration to TS
                        items: section3Articles,
                    }}
                    section4={{
                        title: section4.title,
                        description: section4.description,
                        categoryUrl: section4.slug,
                        // @ts-expect-error fix after migration to TS
                        items: section4Articles,
                    }}
                    sectionMostRecent={{
                        // @ts-expect-error fix after migration to TS
                        items: mostRecentArticles,
                        title: mostRecent.language.sectionTitle,
                    }}
                    sectionAuthors={{
                        title: authorSection.title,
                        description: authorSection.description,
                        // @ts-expect-error fix after migration to TS
                        items: authorSection.authors.map(({ id, name, title, bio, position, authorImageNode }) => ({
                            author: name,
                            jobTitle: title,
                            description: bio,
                            position,
                            image: getImage(authorImageNode),
                            id,
                        })),
                    }}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    appStoreConfig={commonModules.app_store}
                    // @ts-expect-error fix after migration to TS
                    categories={categories}
                    hasSubNav
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query ($locale: String) {
        learnPortal(locale: { eq: $locale }) {
            meta {
                description
                title
                url
            }
            sections {
                position
                type
                description
                title
                slug
                articlesNode {
                    id
                    slug
                    publishedDate
                    formattedPublishDate
                    props {
                        author
                        primaryCategory
                        primaryCategoryUrl
                        slug
                    }
                    language {
                        title
                        description
                    }
                    author {
                        name
                    }
                    childMdx {
                        wordCount {
                            words
                        }
                        timeToRead
                    }
                    heroImageNode {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(quality: 90, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
                        }
                    }
                }
            }
            mostRecent {
                articlesNode {
                    id
                    slug
                    publishedDate
                    formattedPublishDate
                    props {
                        author
                        primaryCategory
                        primaryCategoryUrl
                        slug
                    }
                    language {
                        title
                        description
                    }
                    author {
                        name
                    }
                    childMdx {
                        wordCount {
                            words
                        }
                        timeToRead
                    }
                }
                language {
                    sectionTitle
                }
            }
            categories {
                navTitle
                categoryNavPosition
                categoryUrl
            }
            authorSection {
                title
                description
                authors {
                    id
                    name
                    title
                    bio
                    position
                    authorImageNode {
                        childImageSharp {
                            gatsbyImageData(quality: 90, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    }
`;

export default LearnPortal;
